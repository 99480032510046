.falling-beer {
  position: fixed;
  top: -100px;
  width: 50px; /* Adjust size as needed */
  animation-name: fall;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes fall {
  to {
    top: 100vh;
  }
}
